<template>
    <div class="q-pa-md">
    <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-6">
                <q-input
                  v-model="user.firstName"
                  :dense="true"
                  label="First Name"
                  outlined
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="user.lastName"
                  :dense="true"
                  label="Last Name"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-input
                  v-model="user.email"
                  :dense="true"
                  label="Email"
                  outlined
                />
              </div>
              <div class="col-5">
                <q-input
                  v-model="user.positionTitle"
                  :dense="true"
                  label="Position Title"
                  outlined
                />
              </div>
              <div class="col-3">
                <q-input
                  v-model="user.officeLocation"
                  :dense="true"
                  label="Office Location"
                  outlined
                />
              </div>
              <div class="col-5">
                <q-btn color="white" text-color="black" label="Update Info" @click="postDetails()"/>
              </div>
            </div>
            <q-separator spaced />
              <div class="row q-col-gutter-x-md q-col-gutter-y-md">
                  <div class="col-5">
                  <q-input
                    v-model="changePassword.password"
                    :dense="true"
                    label="Current Password"
                    type="password"
                    outlined
                  />
                </div>
                <div class="col-7"></div>
                <div class="col-5">
                  <q-input
                    v-model="changePassword.newPassword"
                    :dense="true"
                    label="New Password"
                    type="password"
                    outlined
                  />
                </div>
                <div class="col-7"></div>
                <div class="col-5">
                  <q-input
                    v-model="changePassword.newPasswordConfirm"
                    :dense="true"
                    label="Confirm New Password"
                    type="password"
                    outlined
                  />
                </div>
                <div class="col-7"></div>
                <div class="col-5">
                  <q-btn color="white" text-color="black" label="Reset Password" @click="postChangePassword()"/>
                </div>
              </div>
            <q-separator spaced />
  </div>
</template>

<script>
import axios from '../axios'
import { useQuasar } from 'quasar'

export default {
  name: 'Home',
  components: {

  },
  data() {
    return {
        notify: useQuasar(),
        register: {},
        user: {},
        changePassword: {},
        userDialog: false,
        filter: '',
        columns: [
        { name: 'id', align: 'center', label: 'Id', field: 'id', sortable: true },
        { name: 'email', align: 'center', label: 'Email', field: 'email', sortable: true },
        { name: 'positionTitle', align: 'center', label: 'Position', field: 'positionTitle', sortable: true },
        { name: 'officeLocation', align: 'center', label: 'Office', field: 'officeLocation', sortable: true },
      ]
    }
  },
  created() {
      this.getUser()
  },
  methods: {
    async getUser() {
      await axios
        .get('/api/Account/Details')
        .then(res => {
          this.user = res.data
        })
    },
    async postDetails() {
      await axios
        .post('/api/Account/Details', this.user)
        .then(res => {
          this.user = res.data
          this.notify.notify({
            message: 'Account updated',
            color: 'green'
          })
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
    },
    async postChangePassword() {
      await axios
        .post('/api/Account/ChangePassword', this.changePassword)
        .then(function() {
          this.notify.notify({
            message: 'Password Changed',
            color: 'green'
          })
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
    },
    async showDialog() {
      await this.getUser()
      this.userDialog = true
    }
  },
}
</script>

<style lang="scss">

</style>
